<template>
  <div class="doc-outter">
    <div class="doc-title">
        <h3>运输总条件</h3>
    </div>
    <div class="doc-content">
<div id="contentDesc" class="txt-edit"><p class="MsoNormal" align="center">
        </p><p class="MsoNormal" align="center" style="text-align:left;">
        </p><p class="MsoNormal" align="center" style="text-align:left;">
            </p><h1>
            <a name="_Toc439774340"></a><span style="line-height:1.5;">第一章</span><a name="第一章定义"></a><span style="line-height:1.5;"> 定  义</span></h1>
        <p></p>
        <h1>
            <span></span>
        </h1>
        <p class="MsoNormal">
            <span>&nbsp;</span>
        </p>
        <p class="MsoNormal">
            <b>第一条</b> 《幸福航空有限责任公司旅客、行李国内运输总条件》（以下简称“总条件”）中的下列用语，除具体条款中有其他要求或另有明确规定外，含义如下：<span></span>
        </p>
        <p class="MsoNormal">
                （一）“国内运输”指根据旅客运输合同，其出发地点、约定经停地点和目的地点均在中华人民共和国境内的航空运输。<span></span>
        </p>
        <p class="MsoNormal">
                （二）“幸福”是幸福航空有限责任公司的简称<span>(</span>英文名称：<span>C</span>hina Joy Air.Co. LTD，国际航空运输协会<span>IATA</span>两字代码：<span>JR</span>，国际民用航空组织<span>ICAO</span>指定代码：<span>JOY</span>，国际航空运输协会<span>IATA</span>结算代码<span>929</span>，企业地址：陕西省西安市高新区唐延路<span>35</span>号旺座现代城<span>A</span>座<span>18</span>、<span>19</span>层，网址：<span>www.joy-air.net)</span>。<span></span>
        </p>
        <p class="MsoNormal">
                （三）“承运人”指包括填开客票的航空承运人和承运或约定承运该客票所列旅客及其行李的所有航空承运人。<span></span>
        </p>
        <p class="MsoNormal">
                （四）“缔约承运人”指与旅客或其代理人订立一项航空运输合同的航空运输企业。<span></span>
        </p>
        <p class="MsoNormal">
            （五）“实际承运人”指根据与旅客订立的航空运输合同的承运人的授权，而办理该合同项下全部或部分航空运输的航空运输企业。
        </p>
        <p class="MsoNormal">
            （六）“承运人规定”指除本条件外，幸福依法制定、公布的并于客票填开之日起有效的关于为对旅客及其行李运输管理的规定，包括有效的适用票价及适用条件等。<span></span>
        </p>
        <p class="MsoNormal">
                （七）“销售代理企业”指从事民用航空运输销售代理业务的企业。<span></span>
        </p>
        <p class="MsoNormal">
                （八）“授权销售代理企业”指经幸福授权为幸福航班提供销售业务的代理企业。
        </p>
        <p class="MsoNormal">
                （九）“地面服务代理人”指从事民用航空运输地面服务代理业务的企业。<span>&nbsp; </span>
        </p>
        <p class="MsoNormal">
                （十）“幸福地面服务代理人”指已被幸福指定为其航班提供地面服务的地面服务代理人。<span></span>
        </p>
        <p class="MsoNormal">
                （十一）“旅客”指除机组成员以外经幸福同意在飞机上载运或已经载运的任何人。<span></span>
        </p>
        <p class="MsoNormal">
                （十二）“团体旅客”指统一组织的人数在<span>10</span>人（含，两名儿童一名成人算）以上，航程、乘机日期、航班和舱位等级相同并支付团体票价的旅客。<span></span>
        </p>
        <p class="MsoNormal">
                （十三）“儿童”指旅行开始之日年龄满两周岁（含）但不满<span>12</span>周岁的人。<span></span>
        </p>
        <p class="MsoNormal">
                （十四）“婴儿”指旅行开始之日年龄不满两周岁的人；出于医学、安全等原因的考虑，幸福不接受出生未满14天的婴儿乘机。<span></span>
        </p>
        <p class="MsoNormal">
                （十五）“定座”指承运人对旅客预订的座位、舱位等级或对行李的重量、体积的预留。<span></span>
        </p>
        <p class="MsoNormal">
                （十六）“合同单位”指与幸福签订定座、购票合同的单位。<span></span>
        </p>
        <p class="MsoNormal">
                （十七）“航班”指飞机按规定的航线、日期、时刻的定期飞行。<span></span>
        </p>
        <p class="MsoNormal">
                （十八）“超售”指承运人接受定座数超过航班飞机最大允许旅客座位数，以期满足更多旅客成行需求并将航班座位虚耗降到最低的销售行为。
        </p>
        <p class="MsoNormal">
            （十九）“代码共享航班”指承运人通过协议在另一承运人实际承运的航班上使用自己公司航班号或多家公司通过协议在同一个航班上使用各自的航班号的航班。
        </p>
        <p class="MsoNormal">
            （二十）“旅客定座单”指旅客购票前必须填写的供承运人或其销售代理人据以办理定座和填开客票的业务单据。<span></span>
        </p>
        <p class="MsoNormal">
                （二十一）“有效身份证件”指旅客购票和乘机时必须出示的由政府主管部门规定的证明其身份的证件。如：居民身份证、按规定可使用的有效护照、军官证、警官证、士兵证、文职干部或离退休干部证明，<span>16</span>周岁以下未成年人的学生证、户口簿等有效证件。<span></span>
        </p>
        <p class="MsoNormal">
            &nbsp; &nbsp;（二十二）“客票”是指承运人或其授权代理企业销售或认可并赋予运输权利的有效文件，包括纸质客票和电子客票。<span></span>
        </p>
        <p class="MsoNormal">
            （二十三）“纸质客票”指由承运人或代表承运人所填开的被称为“客票及行李票”的凭证，包括运输合同条件、声明、通知以及乘机联和旅客联等内容。<span></span>
        </p>
        <p class="MsoNormal">
            （二十四）“乘机联”指纸质客票中标明“运输有效”的部分，表示该乘机联适用于指定的两个地点之间的运输。<span></span>
        </p>
        <p class="MsoNormal">
            （二十五）“旅客联”指纸质客票中标明“旅客联”的部分，始终由旅客持有。<span></span>
        </p>
        <p class="MsoNormal">
            （二十六）“电子客票”是指由承运人或销售代理企业销售并赋予运输权利的以电子数据形式体现的有效运输凭证，是纸质客票的电子替代品。<span></span>
        </p>
        <p class="MsoNormal">
            （二十七）<span>“</span>航空运输电子客票行程单<span>”</span>是指旅客购买承运人民用航空运输电子客票的付款凭证或报销凭证，同时具备提示旅客行程的作用。
        </p>
        <p class="MsoNormal">
            （二十八）“联程客票”指列明有两个（含）以上航班的客票。<span></span>
        </p>
        <p class="MsoNormal">
            （二十九）“来回程客票”指从出发地点至目的地点并按原航程返回原出发地点的客票。<span></span>
        </p>
        <p class="MsoNormal">
                （三十）“日”指日历日，一周包括七日。用于发通知时，通知发出日不计算在内；用于确定客票有效期限时，客票填开日或航班飞行开始日，均不计算在内。<span></span>
        </p>
        <p class="MsoNormal">
                （三十一）“定期客票”指列明航班、乘机日期和定妥座位的客票。<span></span>
        </p>
        <p class="MsoNormal">
                （三十二）“不定期客票”指未列明航班、乘机日期或未定妥座位的客票。<span></span>
        </p>
        <p class="MsoNormal">
                （三十三）“普通票价”指在票价适用期内的头等、公务、经济各舱位等级的销售票价中的最高票价。<span></span>
        </p>
        <p class="MsoNormal">
                （三十四）“折扣票价”指在票价适用期内的头等、公务、经济各舱位等级的销售票价中的优惠票价。<span></span>
        </p>
        <p class="MsoNormal">
                （三十五）“特种票价”指不属于普通票价及折扣票价的其它票价。<span></span>
        </p>
        <p class="MsoNormal">
                （三十六）“免费运输”指幸福以飞机运送旅客、行李但不收取报酬（税费除外）的国内航空运输，包括但不限于幸福因礼遇、市场促销、雇员因私或因公出行、常旅客奖励等的免费运输。
        </p>
        <p class="MsoNormal">
            （三十七）“误机”指旅客未按规定时间办妥乘机手续或因旅行证件不符合规定而未能乘机。
        </p>
        <p class="MsoNormal">
            （三十八）“漏乘”指旅客在始发站办理乘机手续后或经停站过站时未搭乘上指定的航班。<span></span>
        </p>
        <p class="MsoNormal">
                （三十九）“错乘”指旅客乘坐了不是客票上列明的航班。<span></span>
        </p>
        <p class="MsoNormal">
                （四十）“行李”指旅客在旅行中为了穿着、使用、舒适或便利而携带的必要或适量的物品和其他个人财物。除另有规定外，包括旅客的托运行李、自理行李。<span></span>
        </p>
        <p class="MsoNormal">
                （四十一）“托运行李”指旅客交由幸福负责照管和运输并填开行李票的行李。<span></span>
        </p>
        <p class="MsoNormal">
                （四十二）“自理行李”指经幸福同意由旅客自行照管的行李。<span></span>
        </p>
        <p class="MsoNormal">
                （四十三）“免费随身携带物品”指在幸福限定的品种和数量范围内并经幸福同意免费由旅客自行携带乘机的零星小件物品。<span></span>
        </p>
        <p class="MsoNormal">
                （四十四）“行李票”指客票中与运输旅客托运行李有关的部分。<span></span>
        </p>
        <p class="MsoNormal">
                （四十五）“行李牌识别联”指幸福专为识别托运行李出具给旅客的凭据。<span></span>
        </p>
        <p class="MsoNormal">
                （四十六）“办理乘机手续”指为幸福或其地服务代理为旅客办理登机牌和托运行李手续的过程。<span></span>
        </p>
        <p class="MsoNormal">
            （四十七）“航班截载时间”指航班停止办理乘机手续时间，除机场或幸福另有规定外，一般均在航班规定离站时间前<span>30</span>分钟。<span></span>
        </p>
        <p class="MsoNormal">
            （四十八）“离站时间”指航班旅客登机后，关机门的时间。<span></span>
        </p>
        <p class="MsoNormal">
                （四十九）“约定经停地点”指航班除出发地点和目的地点以外，在客票或幸福的班期时刻表内列明作为旅客旅行路线上预订停留的地点。<span></span>
        </p>
        <p class="MsoNormal">
            （五十）“中途分程”指经幸福事先同意，旅客在出发地点和目的地点间旅行时由旅客有意安排在某个地点的旅程间断。
        </p>
        <p class="MsoNormal">
                （五十一）“转机”旅客在出发地点和目的地点间旅行时，在中间地点乘坐同一承运人的其它航班或其它承运人的航班到达目的地。<span></span>
        </p>
        <p class="MsoNormal">
            （五十二）“不可抗力”指非正常的、无法预见的并且无法控制的情况，即使采取一切可能的措施，仍不能避免其后果的发生。
        </p>
        <p class="MsoNormal">
                （五十三）“损失”指在运输中或与运输有关或在承运人提供的其他服务时发生的损失，包括死亡、受伤、延误、丢失、部分丢失或其他损坏。<span></span>
        </p>
        <p class="MsoNormal">
            &nbsp;
        </p>
        <h1>
            <a name="_Toc439774341"></a><a name="第二章适用范围"></a>第二章  适用范围<span></span>
        </h1>
        <p class="MsoNormal" align="center">
            &nbsp;
        </p>
        <p class="MsoNormal">
            <b>第二条<span></span></b>
        </p>
        <p class="MsoNormal">
            （一）除本条第（二）、（三）、（四）、（五）、（六）款中另有规定外，本条件适用于幸福以飞机运送旅客、行李而收取报酬的国内航空运输；特殊管理的国内航线除外。
        </p>
        <p class="MsoNormal">
            （二）除免费运输条件、合同、票证另有规定外，本条件亦适用于免费运输。<span></span>
        </p>
        <p class="MsoNormal">
            （三）根据幸福包机合同提供的运输，接受包机运输的旅客及行李应遵守幸福包机合同条款规定，包机合同未约定的内容，以本条件规定为准。<span></span>
        </p>
        <p class="MsoNormal">
            （四）除另有约定外，本条件亦适用于电子客票。
        </p>
        <p class="MsoNormal">
            （五）在本条件中如果含有与国家法律、政府规定、命令或要求不一致的条款，以国家法律、政府规定、命令或要求为准；本条件的其余条款仍然有效。<span></span>
        </p>
        <p class="MsoBodyTextIndent2">
            （六）在本条件中如果含有与幸福最新颁布的规定不一致的条款，一律以幸福最新颁布的规定为准；除不一致的条款外，本条件的其余条款仍然有效。<span></span>
        </p>
        <p class="MsoNormal">
            &nbsp;
        </p>
        <h1>
            <a name="_Toc439774342"></a><a name="第三章客票"></a>第三章<span>&nbsp; </span>客  票<span></span>
        </h1>
        <p class="MsoNormal" align="center">
            <b>第一节  一般规定<span></span></b>
        </p>
        <p class="MsoNormal">
            &nbsp;
        </p>
        <p class="MsoNormal">
                <b>第三条</b>&nbsp; 客票是承运人和客票上所列姓名的旅客之间运输合同的初步证据。幸福只向持有由幸福或其销售代理企业填开的客票的旅客、或只向持有由幸福或其销售代理企业填开的作为付款或部分付款证明的其他运输凭证的旅客提供运输。客票始终是出票承运人的财产。客票中的合同条件是幸福运输总条件部分条款的概述。<span></span>
        </p>
        <p class="MsoNormal" align="left">
            <b>第四条 </b>旅客只有按照幸福航空规定（包括信用支付安排）支付票款后，幸福
        </p>
        <p class="MsoNormal" align="left">
            航空或其销售代理人才有义务向旅客签发客票，客票为记名式，只限客票上所列旅客姓名与身份证件信息一致的旅客本人使用。
        </p>
        <p class="MsoNormal">
            <b>第五条 </b>客票不得转让。转让的客票无效，票款不退。如客票不是由有权乘机<span></span>
        </p>
        <p class="MsoNormal">
            或退票的人出示，而幸福非故意、非过失向出示该客票的人提供了运输或退款，幸福对原客票有权乘机或退票的人，不承担责任。<span></span>
        </p>
        <p class="MsoNormal">
            <b>第六条</b>&nbsp; 未经幸福允许客票不得涂改。涂改的客票无效，票款不退。<span></span>
        </p>
        <p class="MsoNormal">
                <b>第七条</b>&nbsp; 客票使用要求<span></span>
        </p>
        <p class="MsoNormal">
                （一）每一位旅客应单独持有客票。<span></span>
        </p>
        <p class="MsoNormal">
            （二）旅客要求乘机时，应出示有效乘机身份证件，并遵守以下规定：
        </p>
        <p class="MsoNormal">
            1、持纸质客票的旅客未能出示根据幸福规定填开的并包括所乘航班的乘机联和所有其它未使用的乘机联和旅客联的有效客票，无权要求乘机。旅客出示残缺客票或非幸福或其销售代理企业更改的客票，也无权要求乘机。<span></span>
        </p>
        <p class="MsoNormal">
            2、持电子客票旅客应出示有效身份证件，并经幸福或其地面服务代理人验证其电子客票状态有效后，方可要求乘机。《航空运输电子客票行程单》仅作为旅客购买电子客票的报销凭证，具备提示旅客行程的作用，不作为机场办理乘机手续和安全检查的必要凭证。
        </p>
        <p class="MsoNormal">
            （三）除幸福另有规定外，客票的所有航段必须按照客票所列明的航程，从始发地点开始顺序使用。如客票的第一航段未被使用，而旅客在约定的经停地点开始旅行，该客票运输无效，幸福不予接受运输及退款。<span></span>
        </p>
        <p class="MsoNormal">
            （四）每一航段必须列明舱位等级，并在航班上定妥座位和日期后方可由幸福接受运输。对未定妥座位的航段，幸福应按旅客的申请，根据适用的票价和所申请航班的座位可利用情况为旅客预订座位。<span></span>
        </p>
        <p class="MsoNormal">
            （五）旅客应在客票有效期内，完成客票上列明的全部航程。<span></span>
        </p>
        <p class="MsoNormal">
            （六）旅客应按客票列明的航程旅行，未经幸福同意不得在经停地点提前终止旅行。
        </p>
        <p class="MsoNormal">
            （七）含有国内航段的国际联程客票，其国内航段的乘机联可直接使用，不需换开成国内客票。<span></span>
        </p>
        <p class="MsoNormal">
            （八）旅客在我国境外购买的用纸质国际客票填开的国内航空运输客票，应换开成我国国内客票后才能使用。国际<span>IET</span>电子客票，不需要换开成我国国内客票，可直接使用。<span></span>
        </p>
        <p class="MsoNormal">
            （九）定期客票只适用于客票上列明的承运人、乘机日期、航班和舱位等级；不定期客票应在定妥座位后方能使用。
        </p>
        <p class="MsoNormal">

        </p>
        <p class="MsoNormal" align="center">
            <b>第二节<span> </span>客票的有效期<span></span></b>
        </p>
        <p class="MsoNormal">
            &nbsp;
        </p>
        <p class="MsoNormal">
            &nbsp;<b>第八条<span></span></b>
        </p>
        <p class="MsoNormal">
            &nbsp; （一）除另有约定外，客票有效期的计算，从旅行开始或填开客票之日的次日零时起至有效期满之日的次日零时为止。<span></span>
        </p>
        <p class="MsoNormal">
            &nbsp; （二）除另有约定外，普通票价的客票有效期自旅行开始之日起，一年内运输有效；如果客票全部未使用，则视为不定期客票，客票有效期从填开客票之日起，一年内运输有效。<span></span>
        </p>
        <p class="MsoNormal">
            &nbsp; （三）折扣票价及特种票价的客票有效期，按照幸福折扣票价和特种票价客票使用规定计算。<span></span>
        </p>
        <p class="MsoNormal" align="center">
            &nbsp;
        </p>
        <p class="MsoNormal" align="center">
            <b> </b><b>第三节<span>&nbsp; </span>客票有效期的延长<span></span></b>
        </p>
        <p class="MsoNormal">
            &nbsp;
        </p>
        <p class="MsoNormal">
                <b>第九条<span></span></b>
        </p>
        <p class="MsoNormal">
            &nbsp; （一）由于幸福的下列原因之一，致使旅客未能在客票有效期内旅行，其客票有效期将延长至幸福能够按照该客票已付票价的舱位等级提供座位的第一个航班为止：<span></span>
        </p>
        <p class="MsoNormal">
            &nbsp; 1、取消旅客已经定妥座位的航班；<span></span>
        </p>
        <p class="MsoNormal">
            &nbsp; 2、取消的航班约定经停地点中含有旅客的出发地点、目的地点；<span></span>
        </p>
        <p class="MsoNormal">
            &nbsp; 3、未能在合理的时间内按照班期时刻进行飞行；<span></span>
        </p>
        <p class="MsoNormal">
            &nbsp; 4、造成旅客已定妥座位的航班衔接错失；<span></span>
        </p>
        <p class="MsoNormal">
                &nbsp;5、更换了旅客的舱位等级；<span></span>
        </p>
        <p class="MsoNormal">
            6、未能提供事先已定妥的座位。<span></span>
        </p>
        <p class="MsoNormal">
            （二）持普通票价客票或与普通票价客票有效期相同的折扣票价或特种票价客票的旅客未能在客票有效期内旅行，是由于幸福在旅客定座时未能按其客票的舱位等级提供航班座位，其客票有效期可以延长至幸福能够按照该客票已付票价的舱位等级提供座位的第一个航班为止，但延长期不得超过七天。<span></span>
        </p>
        <p class="MsoNormal">
            （三）持与普通票价客票有效期不同的折扣票价或特殊票价的有效期按幸福折扣票价或特殊票价客票使用规定执行。<span></span>
        </p>
        <p class="MsoNormal">
            &nbsp; （四）已开始旅行的旅客在其持有的客票有效期内因病使旅行受阻时，除幸福对所付票价另有规定外，幸福可将该旅客的客票有效期延长至根据医生诊断证明确定该旅客适宜旅行之日为止；或延长至适宜旅行之日以后承运人能够按照该旅客已付票价的舱位等级提供座位的自恢复旅行地点起的第一个航班为止。如客票中未使用的乘机联包含一个或一个以上中途分程地点，该客票有效期的延长不能超过自该医生诊断证明出具之日起<span>3</span>个月。幸福也可同等延长患病旅客的陪伴直系亲属的客票有效期。<span></span>
        </p>
        <p class="MsoNormal">
            （五）如旅客在旅途中死亡，该旅客陪同人员的客票可用延长客票有效期的方法予更改。如已开始旅行旅客的直系亲属死亡，该旅客及其陪同的直系亲属的客票也可予以更改。此种更改应在收到死亡证明书后办理，此种客票有效期的延长不得超过死亡证明中列明的死亡之日起<span>45</span>日。<span></span>
        </p>
        <p class="MsoNormal">
            &nbsp;
        </p>
        <p class="MsoNormal" align="center">
            <b>第四节<span>&nbsp; </span>客票遗失<span></span></b>
        </p>
        <p class="MsoNormal">
            &nbsp;
        </p>
        <p class="MsoNormal">
            <b>第十条</b>&nbsp; 遗失纸质客票的挂失<span></span>
        </p>
        <p class="MsoNormal">
            （一）旅客的客票全部或部分遗失或残损，或旅客出示的客票未能包括旅客联和所有未使用的乘机联，旅客应以书面形式向幸福或其授权的销售代理人申请挂失。<span></span>
        </p>
        <p class="MsoNormal">
            （二）旅客申请挂失，不需提供公安机关出具的遗失证明，但须出示其有效身份证件；如申请挂失者不是旅客本人，需出示旅客本人和挂失者的有效身份证件，并提供书面申请、原购票的出票人联及足以证明该客票遗失的其他资料或证明。<span></span>
        </p>
        <p class="MsoNormal">
            （三）在旅客申请挂失前，客票如全部或部分已被冒用或冒退，幸福不承担责任。<span></span>
        </p>
        <p class="MsoNormal">
            <b>第十一条</b> &nbsp;重新购票<span></span>
        </p>
        <p class="MsoNormal">
            纸质定期客票遗失后，如旅客要求继续乘坐遗失客票上列明的航班或后续航班，需重新购买客票。<span> </span>
        </p>
        <p class="MsoNormal">
            第十二条&nbsp; 遗失纸质客票的退款<span></span>
        </p>
        <p class="MsoNormal">
            &nbsp; （一）不定期客票遗失，旅客应当及时按本章第十条规定的手续以书面形式向幸福申请挂失。经查证该客票未被冒用、冒退，待客票有效期满后<span>30</span>日内，凭符合本条件第十条第（二）款规定的资料与证明办理退款手续。<span></span>
        </p>
        <p class="MsoNormal">
            &nbsp; （二）定期客票遗失应在遗失客票有效期满<span>30</span>日内，经幸福查证后，凭符合本条件第十条第（二）款规定的资料与证明以及重新购票的旅客联，予以办理遗失客票的退款手续。<span></span>
        </p>
        <p class="MsoNormal">
                <b>第十三条</b> 电子客票行程单的遗失<span></span>
        </p>
        <p class="MsoNormal" align="left">
                1、电子客票行程单遗失不补，旅客以书面形式向幸福提出申请，可凭有效身份证件在原购票地点或幸福营业部办理购票证明。<span></span>
        </p>
        <p class="MsoNormal" align="left">
                2、购票证明只证明旅客的购票行为，不是有效的旅行证件和报销凭证。<span></span>
        </p>
                <p class="MsoNormal">
            &nbsp;
        </p>
        <h1>
            <a name="_Toc439774343"></a><a name="第四章票价和费用"></a>第四章  票价和税费<span></span>
        </h1>
        <p class="MsoNormal">
            &nbsp;
        </p>
        <p class="MsoNormal">
            第十四条&nbsp; 票价<span></span>
        </p>
        <p class="MsoNormal">
                （一）除另有规定外，客票价指旅客由出发地机场至目的地机场的航空运输价格，不包括机场与市区之间或同一城市机场与机场之间的地面运输费用。<span></span>
        </p>
        <p class="MsoNormal">
                （二）幸福公布的票价，适用于直达航班运输。如旅客要求经停或转乘其他航班时，应按实际航段分段相加计算票价。<span></span>
        </p>
        <p class="MsoNormal">
                （三）客票价为旅客开始乘机之日适用的票价。客票出售后，如票价调整，票款不作变动。<span></span>
        </p>
        <p class="MsoNormal">
                （四）使用折扣票价或特种票价的旅客，应遵守该折扣票价或特种票价规定的条件。<span></span>
        </p>
        <p class="MsoNormal">
            第十五条&nbsp; 票款<span></span>
        </p>
        <p class="MsoNormal">
                （一）旅客应按国家规定的货币和付款方式交付票款，除幸福与旅客另有协议外，票款一律现付。<span></span>
        </p>
        <p class="MsoNormal">
                （二）当收取的票款与适用的票价不符或计算有错误时，应按照幸福规定，由旅客补付不足的票款或由幸福退还多收的票款。<span></span>
        </p>
        <p class="MsoNormal">
                （三）客票价以人民币<span>10</span>元为计算单位，幸福收取或支付的任何其他费用均以人民币元为计算单位，尾数一律四舍五入。<span></span>
        </p>
        <p class="MsoNormal">
            第十六条&nbsp; 革命伤残军人、因公致残的人民警察、儿童、婴儿票价<span></span>
        </p>
        <p class="MsoNormal">
                （一）革命伤残军人和因公致残的人民警察凭民政部颁发的《中华人民共和国革命伤残军人证》和《中华人民共和国人民警察伤残怃恤证》，按照同一航班成人适用普通票价的<span>50%</span>购票。<span></span>
        </p>
        <p class="MsoNormal">
                （二）儿童按照同一航班成人适用普通票价的<span>50%</span>购买儿童票，并提供座位。<span></span>
        </p>
        <p class="MsoNormal">
                （三）婴儿按照同一航班成人适用普通票价的<span>10%</span>购买婴儿票，不提供座位；如需要单独占用座位时，应购买儿童票。每一年满<span>18</span>周岁、具有完全民事行为能力的成人旅客可携带一名婴儿旅客，超过一名时，超过的人数应购儿童票。<span></span>
        </p>
        <p class="MsoNormal">
                （四）幸福销售以上优惠客票，不得附加购票时限等限制条件。<span></span>
        </p>
        <p class="MsoNormal">
            第十七条&nbsp; 税费<span></span>
        </p>
        <p class="MsoNormal">
            &nbsp; 政府、有关当局或机场经营人规定的对旅客或由旅客享用的任何服务或设施而征收的税款或费用不包括在公布票价之内。该项税款或费用应由旅客支付。<span></span>
        </p>
        <p class="MsoNormal">
            &nbsp;
        </p>
        <h1>
            <a name="_Toc439774344"></a><a name="第五章定座"></a>第五章  定  座<span></span>
        </h1>
        <p class="MsoNormal" align="center">
            &nbsp;
        </p>
        <p class="MsoNormal">
            第十八条&nbsp; 定座基本要求<span></span>
        </p>
        <p class="MsoNormal">
            （一）定座只有在旅客按照幸福规定的手续和购票时限内交付票款，经幸福或其授权销售代理企业确认后，方能认为座位已经定妥和有效；未经幸福或其销售代理企业记录认可，不得认为定座已确认。<span></span>
        </p>
        <p class="MsoNormal">
            （二）按照幸福规定，折扣票价和特种票价可以附有限制或排除旅客签转、更改、退票、取消定座权利的条件。<span></span>
        </p>
        <p class="MsoNormal">
            （三）旅客出示的客票经证实其定座记录是无效的，幸福不保证旅客乘坐原定航班成行，但可视情况协助旅客成行，产生的费用由造成定座记录无效的责任人承担。<span></span>
        </p>
        <p class="MsoNormal">
            第十九条&nbsp; 合同单位定座<span></span>
        </p>
        <p class="MsoNormal">
            &nbsp; 合同单位应按合同的约定定座。<span></span>
        </p>
        <p class="MsoNormal">
            第二十条&nbsp; 购票时限<span></span>
        </p>
        <p class="MsoNormal">
            &nbsp; 已经定妥的座位，旅客应在幸福规定或预先约定的时限内完成购买。否则，原定座位不予保留。<span></span>
        </p>
        <p class="MsoNormal">
            第二十一条&nbsp; 个人资料<span></span>
        </p>
        <p class="MsoNormal">
            &nbsp; 旅客认可向幸福提供的个人资料，旨在用于定座和安排相关的运输服务。为此，旅客授权幸福保留其个人资料并善加保密且可将必须的资料传递给政府机构、幸福有关部门、其他相关承运人或相关服务的提供者。旅客有责任按照幸福规定提供真实、准确、完整的有效身份信息、联系电话等。
        </p>
        <p class="MsoNormal">
            第二十二条&nbsp; 定座优先权<span></span>
        </p>
        <p class="MsoNormal">
            （一）执行党和国家政治、外交事务、紧急救援等特殊任务的旅客，重要旅客，多航段选择幸福航班或高舱位定座的旅客，非自愿改变航程的旅客，在航班有可利用座位的条件下，可优先定座。<span></span>
        </p>
        <p class="MsoNormal">
            &nbsp; （二）旅客持没有定妥座位的全部或部分航段的客票要求定座，或持已定妥座位的全部或部分航段的客票要求更改定座，都无权要求优先。<span></span>
        </p>
        <p class="MsoNormal">
            第二十三条&nbsp; 机上座位安排<span></span>
        </p>
        <p class="MsoNormal">
            &nbsp; 幸福按旅客已经定妥的航班和舱位等级提供座位，不保证在飞机上提供旅客所要求的指定座位。<span></span>
        </p>
        <p class="MsoNormal">
            第二十四条&nbsp; 座位再证实<span></span>
        </p>
        <p class="MsoBodyTextIndent">
            旅客持有在境外购买的定妥座位的幸福国内段客票，须按幸福规定办理座位再证实手续。<span></span>
        </p>
        <p class="MsoNormal">
            &nbsp;
        </p>
        <h1 style="margin-left:0cm;">
            <a name="第六章拒绝运输和限制运输"></a><a name="_Toc439774345"></a>第六章<span>&nbsp; </span>拒绝运输和限制运输<span></span>
        </h1>
        <p class="MsoNormal" align="center">
            &nbsp;
        </p>
        <p class="MsoNormal">
            第二十五条&nbsp; 拒绝运输权<span></span>
        </p>
        <p class="MsoNormal">
            &nbsp; 幸福出于安全原因或根据自己合理的判断，认为属下列情形之一时，有权拒绝运输旅客及其行李：<span></span>
        </p>
        <p class="MsoNormal">
                （一）为遵守国家的有关法律、法规和政府规章及其它规范性文件的规定；<span></span>
        </p>
        <p class="MsoNormal">
            （二）旅客的行为、年龄、精神或身体健康状况不适合航空旅行，或影响其他旅客正当权利的实现及对机上安全秩序产生不利影响，或对其自身或其他人员或财产可能造成任何危险或危害；<span></span>
        </p>
        <p class="MsoNormal">
                （三）旅客不遵守国家的有关法律、法规和政府规章及其它规范性文件的规定，或不遵守承运人的规定；<span></span>
        </p>
        <p class="MsoNormal">
                （四）旅客拒绝接受安全检查；<span></span>
        </p>
        <p class="MsoNormal">
                （五）旅客未支付适用的票价、费用以及（或）未承兑其与幸福或有关承运人之间的信用付款；<span></span>
        </p>
        <p class="MsoNormal">
            （六）旅客未能出示本人的有效身份证件；<span></span>
        </p>
        <p class="MsoNormal">
                （七）旅客出示的客票经证明是非法获得或不是在出票承运人或其销售代理企业处购买的、或属已挂失或被盗的、或是伪造的、或不是由幸福或其销售代理企业更改的乘机联或乘机联被涂改的；<span></span>
        </p>
        <p class="MsoNormal">
            （八）出示客票的人不能证明本人即是客票上“旅客姓名”栏内列明的人。<span></span>
        </p>
        <p class="MsoNormal">
            <b>第二十六条<span>&nbsp; </span></b>幸福根据市场信息以及收益管理系统数据分析，为满足更多旅客成行需求并将航班座位虚耗降到最低，幸福可能会在某些航班上进行适当的超售。当航班超售时，幸福有权决定超售航班的旅客及行李的载运安排，对不能成行旅客及行李，按照第五十三条的规定处理。<span></span>
        </p>
        <p class="MsoNormal">
            第二十七条&nbsp; 对被拒绝运输旅客的安排<span></span>
        </p>
        <p class="MsoNormal">
            &nbsp; 对被拒绝运输的旅客，承运人按下列规定办理：<span></span>
        </p>
        <p class="MsoNormal">
                （一）属本章第二十五条第（一）款情形的旅客，已购客票按本条件第四十三条非自愿退票的规定办理。<span></span>
        </p>
        <p class="MsoNormal">
            （二）属本章第二十五条第（二）款，由于年龄、精神或身体状况不适合旅行且幸福根据自己合理的判断认为旅客不存在过失责任的，已购客票按本条件第四十三条非自愿退票的规定办理；旅客有过失责任的，已购客票按本条件第四十四条自愿退票的规定办理。<span></span>
        </p>
        <p class="MsoNormal">
            （三）属本章第二十五条（三）、（四）款情形的旅客，已购客票按本条件第四十四条自愿退票的规定办理。<span></span>
        </p>
        <p class="MsoNormal">
                （四）属本章第二十五条第（五）款情形的旅客，按本条件第十五条第（二）款的规定，由旅客补付不足的票款或税费，或按本条件第四十三条非自愿退票的规定办理，退还旅客已支付的票款。<span></span>
        </p>
        <p class="MsoNormal">
                （五）属本章第二十五条第（六）款情形的旅客，按本条件第三十七条自愿改变航班、日期或按本条件第四十四条、第五十一条的规定办理。<span></span>
        </p>
        <p class="MsoNormal">
                （六）属本章第二十五条第（七）、（八）款情形的旅客，承运人保留扣留其客票的权利，必要时呈报有关主管部门处理。<span></span>
        </p>
        <p class="MsoNormal">
            第二十八条&nbsp; 限制运输<span></span>
        </p>
        <p class="MsoNormal">
            （一）无成人陪伴儿童、无自理能力人、孕妇、病患旅客、犯人或需要特殊帮助的旅客等，由于年龄、身体或精神状况在旅途中需要特殊照顾或在一定条件下才能运输，只有在符合幸福规定的条件下，经幸福预先同意并在必要时做出安排后方予载运。残疾旅客运输幸福航空按照民航局下发《残疾人航空运输管理办法》执行。
        </p>
        <p class="MsoNormal">
            （二）限制运输旅客的数量：出于安全的考虑，幸福对每一航班限制运输旅客的数量进行相应的控制。
        </p>
        <p class="MsoNormal">
            &nbsp;
        </p>
        <h1>
            <a name="第七章购票"></a><a name="_Toc439774346"></a>第七章<span>&nbsp; </span>购  票<span></span>
        </h1>
        <p class="MsoNormal">

        </p>
        <p class="MsoNormal">
            第二十九条&nbsp; 一般规定<span>&nbsp; </span>
        </p>
        <p class="MsoNormal">
                （一）旅客可在幸福或其销售代理企业的售票处购票，也可以登录幸福网站（www.joy-air.net）购买电子客票。
        </p>
        <p class="MsoNormal">
                （二）旅客购票须凭本人有效身份证件或公安机关出具的其他有效身份证件，并填写《旅客定座单》；购买电子客票应按幸福要求如实申报旅客有效身份证件、联系电话等信息，旅客应对其申报信息的真实性、准确性负责，否则由此产生的损失由旅客自行承担。<span></span>
        </p>
        <p class="MsoNormal">
                （三）购买儿童票、婴儿票，应提供儿童、婴儿出生日期的有效证明。<span></span>
        </p>
        <p class="MsoNormal">
                （四）限制运输旅客购票，应在幸福或其授权的销售代理人的售票处提出申请并按幸福要求提供相关证明，经幸福或有关承运人同意后，方可购票。<span></span>
        </p>
        <p class="MsoNormal">
            第三十条 &nbsp;幸福或其销售代理企业应根据旅客的要求，出售单程、联程或来回程客票。
        </p>
        <p class="MsoNormal">
            &nbsp;
        </p>
        <h1 style="margin-left:0cm;">
            <a name="第八章班期时刻、航班取消与变更"></a><a name="_Toc439774347"></a>第八章  班期时刻、航班取消与变更<span></span>
        </h1>
        <p class="MsoNormal">
            &nbsp;
        </p>
        <p class="MsoNormal">
                <b>&nbsp;</b><b>第三十一条</b>&nbsp; 班期时刻<span></span>
        </p>
        <p class="MsoNormal">
                （一）幸福应尽力在合理的期限内运送旅客及其行李，遵守公布的在旅行之日内有效的班期时刻。但是，航班时刻表或其他场所所列的时刻，仅供参考，并非航空运输合同的组成部分。
        </p>
        <p class="MsoNormal">
                （二）幸福对班期时刻表或其它公布的班期时刻中的差错或遗漏不承担责任。幸福对其雇员、代理人或幸福的代表就始发或到达时间、日期或任何航班飞行所作的解释也不承担责任。<span></span>
        </p>
        <p class="MsoNormal">
                <b></b><b>第三十二条</b>&nbsp; 航班取消与变更<span></span>
        </p>
        <p class="MsoNormal">
            &nbsp; 有下列情形之一的，幸福可按规定不经事先通知，改变机型或航线、取消、中断、推迟或延期航班飞行：<span></span>
        </p>
        <p class="MsoNormal">
                （一）为遵守国家的法律、法规和政府规章及其它规范性文件的规定；<span></span>
        </p>
        <p class="MsoNormal">
                （二）为保证飞行安全；<span></span>
        </p>
        <p class="MsoNormal">
            （三）其他不可抗力或不可预见的原因；<span></span>
        </p>
        <p class="MsoNormal">
            （四）其他非幸福的原因。<span></span>
        </p>
        <p class="MsoNormal">
            <b>第三十三条</b> 由于本章第三十二条原因之一，幸福取消或延误航班，因而未能向旅客提供已定妥的座位（包括舱位等级），或未能在旅客的目的地点停留，或造成旅客已定妥座位的航班衔接错失，幸福可根据旅客的合理需求<span>, </span>协助采取以下措施之一，产生的费用差额，由旅客自行承担，多退少补：
        </p>
        <p class="MsoNormal">
                （一）为旅客安排有可利用座位的幸福后续航班；<span></span>
        </p>
        <p class="MsoNormal">
            （二）征得旅客及有关承运人的同意后，办理签转手续；<span></span>
        </p>
        <p class="MsoNormal">
                （三）变更原客票列明的航程，安排幸福或其他承运人的航班将旅客运达目的地；<span></span>
        </p>
        <p class="MsoNormal">
                （四）按本条件第四十三条非自愿退票的规定办理。<span></span>
        </p>
        <p class="MsoNormal">
            第三十四条 延误、取消航班的旅客服务<span></span>
        </p>
        <p class="MsoNormal">
            &nbsp; 对延误、取消航班的旅客，幸福应分别按本条件第八十七条、第八十八条、第八十九条和第九十条的规定办理。<span></span>
        </p>
        <p class="MsoNormal">
            &nbsp;
        </p>
        <h1>
            <a name="第九章客票变更"></a><a name="_Toc439774348"></a>第九章  客票变更<span></span>
        </h1>
        <p class="MsoNormal">
            &nbsp;
        </p>
        <p class="MsoNormal">
                <b>&nbsp;</b><b>第三十五条 </b>&nbsp;非自愿变更<span></span>
        </p>
        <p class="MsoNormal">
            由于本条件第九条第（一）款所列的原因之一者，幸福应在航班有可利用座位的条件下，按以下规定办理：<span></span>
        </p>
        <p class="MsoNormal">
            （一）为旅客安排有可利用座位的幸福后续航班；<span></span>
        </p>
        <p class="MsoNormal">
            （二）变更原客票列明的航程，安排幸福或其他承运人的航班将旅客运达目的地，票款、逾重行李费、税费和其他服务费用的差额多退少不补。<span></span>
        </p>
        <p class="MsoNormal">
            第三十六条&nbsp; 自愿改变舱位等级<span></span>
        </p>
        <p class="MsoNormal">
            &nbsp; 旅客购票后，如要求改变舱位等级，幸福及其销售代理企业应根据幸福的规定，在航班有可利用座位和时间允许的条件下予以办理，产生的票款差额多不退少补。<span></span>
        </p>
        <p class="MsoNormal">
            第三十七条&nbsp; 自愿改变航班、日期<span></span>
        </p>
        <p class="MsoNormal">
            旅客购票后，如要求改变航班、日期，幸福及其销售代理企业应根据幸福规定，在航班有可利用座位和时间允许的条件下予以办理，产生的票款差额多不退少补。<span></span>
        </p>
        <p class="MsoNormal">
            &nbsp;
        </p>
        <h1>
            <a name="_Toc439774349"></a>第十章<span>&nbsp; </span>客票签转<span></span>
        </h1>
        <p class="MsoNormal" align="center">
            &nbsp;
        </p>
        <p class="MsoNormal">
            &nbsp;<b>第三十八条</b>&nbsp; 非自愿签转及自愿签转<span></span>
        </p>
        <p class="MsoNormal">
            （一）由于幸福原因导致旅客非自愿改变承运人，在征得旅客及有关承运人的同意后，办理签转手续；由于非幸福原因导致旅客非自愿改变承运人，在征得旅客及有关承运人的同意后，办理签转手续，产生的相关费用由旅客自行承担。<span></span>
        </p>
        <p class="MsoNormal">
            （二）旅客自愿要求改变承运人，应征得原承运人或其授权代理企业的同意，并在新承运人允许及符合下列全部条件下，承运人可予以签转：<span></span>
        </p>
        <p class="MsoNormal">
                １、旅客使用的票价无签转限制；<span></span>
        </p>
        <p class="MsoNormal">
                ２、旅客的客票未改变过舱位等级；<span> </span>
        </p>
        <p class="MsoNormal">
            ３、所签转的承运人与原承运人有票证结算关系且所签转的承运人航班有相应座位等级可利用。<span></span>
        </p>
        <p class="MsoNormal">
                （三）凡不符合本条第（一）、（二）款规定的旅客要求改变承运人，一律按本条件第四十四条自愿退票的规定办理。<span></span>
        </p>
        <p class="MsoNormal">
                （四）幸福的销售代理企业未经特别授权不得为旅客办理签转手续。
        </p>
        <p class="MsoNormal">
            &nbsp;
        </p>
        <h1>
            <a name="第十章退票"></a><a name="_Toc439774350"></a>第十一章  退  票<span></span>
        </h1>
        <p class="MsoNormal">
            &nbsp;
        </p>
        <p class="MsoNormal">
            第三十九条<span>&nbsp; </span>一般规定 <span>&nbsp;</span>
        </p>
        <p class="MsoNormal">
                （一）由于幸福未能按照运输合同提供运输或旅客要求自愿改变其旅行安排，对旅客未能使用的全部或部分幸福客票，幸福应按规定办理退票。<span></span>
        </p>
        <p class="MsoNormal">
                （二）旅客要求退票，应填妥幸福规定的退款单。除遗失客票的情形外，购买纸质客票的旅客必须凭客票未使用的全部乘机联和旅客联，方可办理退票；购买电子客票的旅客凭本人有效身份证件办理退票，并交回已打印的电子客票行程单，退票时其电子客票须为有效状态。<span></span>
        </p>
        <p class="MsoNormal">
            第四十条 <b></b>退票受款人<span></span>
        </p>
        <p class="MsoNormal">
                （一）幸福有权向客票上列明姓名的旅客本人办理退票。<span></span>
        </p>
        <p class="MsoNormal">
                （二）当客票上列明的旅客不是该客票的付款人，幸福应按列明的退票限制条件将票款退给付款人或其指定人。<span></span>
        </p>
        <p class="MsoNormal">
                （三）旅客退票应出示本人有效身份证件；如退票受款人不是客票上列明的旅客本人，应出示旅客本人和退票受款人的有效身份证件。<span></span>
        </p>
        <p class="MsoNormal">
                （四）幸福将票款退给持有未使用航段的有效客票并符合本条第（一）、第（二）、第（三）款规定的人，应被视为正当退票。幸福也随即解除责任。<span></span>
        </p>
        <p class="MsoNormal">
            第四十一条&nbsp; 退票期限<span></span>
        </p>
        <p class="MsoNormal">
            &nbsp; 除本条件第十二条规定情况以外，旅客要求退票，应在其客票有效期内向幸福提出并办理退款手续，否则幸福有权拒绝办理。<span></span>
        </p>
        <p class="MsoNormal">
            第四十二条&nbsp; 退票地点<span></span>
        </p>
        <p class="MsoNormal">
                （一）旅客非自愿退票，可在原购票地、航班始发地、经停地、终止地的幸福营业部或引起非自愿退票发生地的幸福授权的代理企业售票处办理。<span></span>
        </p>
        <p class="MsoNormal">
                （二）旅客自愿退票，应在下列地点办理：<span></span>
        </p>
        <p class="MsoNormal">
                1、在出票地要求退票，除另有限制规定外，可在原购票的售票处或幸福营业部办理；<span></span>
        </p>
        <p class="MsoNormal">
                2、在出票地以外的航班始发地或终止旅行地要求退票，可在当地的幸福营业部办理；如当地无幸福营业部，可在经幸福特别授权的当地销售代理企业售票处办理。<span></span>
        </p>
        <p class="MsoNormal">
            第四十三条 非自愿退票<span></span>
        </p>
        <p class="MsoNormal">
            （一）由于本条件第九条第（一）款所列原因之一者，旅客要求退票，始发地<span></span>
        </p>
        <p class="MsoNormal">
            应退还旅客所付全部票款；经停地应按旅客的订座舱位退还未使用航段的票款，但不得超过原付票款金额。均不收取退票费。<span></span>
        </p>
        <p class="MsoNormal">
            （二）持由定期客票更改为不定期客票的旅客在确认座位时，若因幸福航段<span></span>
        </p>
        <p class="MsoNormal">
            取消原因提出退票，按“非自愿退票”处理，不收退票手续费。<span></span>
        </p>
        <p class="MsoNormal">
            （三）旅客因病要求退票，需提供县级（含）以上医疗单位出具的医生诊断证明、病历和医药费收费单；始发地应退还旅客所付全部票款；经停地退还未使用航段的票款，但不得超过付票款金额。均免收退票费。患病旅客的陪伴人员要求退票，应于航班起飞前提出退票申请，并与患病旅客同时办理退票手续，陪伴人员限额两名，可免收退票费。
        </p>
        <p class="MsoNormal">
                <b>&nbsp;</b><b>第四十四条</b>&nbsp; 自愿退票<span></span>
        </p>
        <p class="MsoNormal">
            旅客自愿要求退票，分别按下列规定办理：<span></span>
        </p>
        <p class="MsoNormal">
            （一）客票全部未使用，按《幸福航空国内航班多等级舱位管理规定》扣除退票手续费后，退还剩余票款及税费。<span></span>
        </p>
        <p class="MsoNormal">
            （二）客票已部分使用，按《幸福航空国内航班多等级舱位管理规定》扣除已使用航段的适用票价、相关税费和退票手续费，将余额退还旅客<span></span>
        </p>
        <p class="MsoNormal">
            （三）除上述两条规定外，幸福另有特殊规定的客票，按幸福规定办理。<span></span>
        </p>
        <p class="MsoNormal">
            （四）持革命伤残军人、因工致残人民警察、婴儿客票的旅客要求退票，免收退票费。<span></span>
        </p>
        <p class="MsoNormal">
            （五）旅客在航班的经停地自动终止旅行，该航班未使用航段的票款不退。<span></span>
        </p>
        <p class="MsoNormal">
            &nbsp;
        </p>
        <h1>
            <a name="第十一章团队旅客"></a><a name="_Toc439774351"></a><a name="第十一章团体旅客"></a>第十二章<span>&nbsp; </span>团体旅客<span></span>
        </h1>
        <p class="MsoNormal">
            &nbsp;
        </p>
        <p class="MsoNormal">
            第四十五条 &nbsp;人数的计算<span></span>
        </p>
        <p class="MsoNormal">
            &nbsp; 除另有规定外，团体旅客是指符合本条件第一条第（十二）款定义的旅客。凡购买婴儿、儿童及其它折扣票价或特种票价客票的旅客不得计算在团体人数内。<span></span>
        </p>
        <p class="MsoNormal">
            第四十六条&nbsp; 购票时限<span></span>
        </p>
        <p class="MsoNormal">
            &nbsp; 已经定妥的座位，团体旅客应在幸福规定或预先约定的时限内购买客票。否则，所定座位不予保留。<span></span>
        </p>
        <p class="MsoNormal">
            第四十七条&nbsp; 退票地点<span></span>
        </p>
        <p class="MsoNormal">
                （一）团体旅客自愿退票只限在原购票的售票处办理。<span></span>
        </p>
        <p class="MsoNormal">
                （二）团体旅客非自愿退票，可在本条件第四十二条第（一）款规定的退票地点办理。<span></span>
        </p>
        <p class="MsoNormal">
            第四十八条 非自愿或因病要求变更或退票<span></span>
        </p>
        <p class="MsoNormal">
            &nbsp; 团体旅客非自愿或团体旅客中部分成员因病要求变更或退票，分别按照本条件第三十三条、第四十三条的规定办理。<span></span>
        </p>
        <p class="MsoNormal">
            第四十九条&nbsp; 自愿退票<span></span>
        </p>
        <p class="MsoNormal">
            团体旅客购票后自愿要求退票，根据其相应折扣，按下列规定执行：<span></span>
        </p>
        <p class="MsoNormal">
            （一）全部未使用的团队（含单段、多段及往返团队），退票费计算首先分别判断相应航段折扣，再按照第一航段航班时间确定退票费率。五折以上：团体旅客自愿退票在航班规定离站时间前<span> 48 </span>小时提出，收取票面价<span> 50%</span>的退票费；在航班<span></span>
        </p>
        <p class="MsoNormal">
            规定离站时间<span> 48 </span>小时（含）内及航班规定截载时间后提出，收取<span> 100%</span>退票费。 五折（含）以下，收取票面价<span> 100%</span>的退票费。<span></span>
        </p>
        <p class="MsoNormal">
            （二）若多段及往返团队使用其中一段后，其余段提出申退，收取票面价<span> 100%</span>的退票费。<span></span>
        </p>
        <p class="MsoNormal">
            第五十条 团体部分成员自愿退票<span></span>
        </p>
        <p class="MsoNormal">
            团体旅客中部分成员自愿要求退票，除票价附有限制条件者外，按下列规定办理：<span>&nbsp; </span>
        </p>
        <p class="MsoNormal">
            （一）如团队剩余乘机人数少于政策中规定的最少成团人数或少于整团人数<span> 50%</span>时，则不允许退票。<span></span>
        </p>
        <p class="MsoNormal">
            （二）如团队剩余乘机人数不少于政策中规定的最少成团人数，且不少于整团人数<span> 50%</span>时，按以下规定办理：退票旅客如客票行程全部未使用按照第四十九条（一）规定办理；如退票旅客部分行程未使用（即来回程、联程、缺口程退单程的情况），按照第四十九条（二）规定办理。<span>&nbsp; </span>
        </p>
        <p class="MsoNormal">
            <b>第五十一条</b>&nbsp; 团体旅客误机<span></span>
        </p>
        <p class="MsoNormal">
            团体旅客误机，客票作废，票款不退。 <span></span>
        </p>
        <p class="MsoNormal">
            &nbsp;
        </p>
        <h1>
            <a name="_Toc439774352"></a><a name="第十二章乘机"></a>第十三章  超售<span></span>
        </h1>
        <p class="MsoNormal" align="center">
            &nbsp;
        </p>
        <p class="MsoNormal">
            <b>第五十二条<span>&nbsp; </span></b>幸福根据市场信息以及收益管理系统数据分析，为满足更多旅客成行需求并将航班座位虚耗降到最低，幸福可能会在某些航班上进行适当的超售。<b></b>
        </p>
        <p class="MsoNormal">
            <b>第五十三条</b>&nbsp; 超售处理原则<span></span>
        </p>
        <p class="MsoNormal">
            （一）幸福应以适当的方式告知旅客超售的含义以及超售旅客享有的权利。<span></span>
        </p>
        <p class="MsoNormal">
            （二）当航班出现超售时，幸福将首先寻找自愿放弃座位的旅客，并给予旅客一定的补偿。<span></span>
        </p>
        <p class="MsoNormal">
            （三）当没有足够的旅客自愿放弃座位时，幸福可根据规定的优先登机原则拒绝部分旅客登机。<span></span>
        </p>
        <p class="MsoNormal">
            （四）幸福为被拒绝登机的旅客提供相应的服务并给予一定的补偿。<span></span>
        </p>
        <p class="MsoNormal">
            &nbsp;
        </p>
        <h1>
            <a name="_Toc439774353"></a>第十四章<span>&nbsp; </span>代码共享航班<span></span>
        </h1>
        <p class="MsoNormal" align="center">
            &nbsp;
        </p>
        <p class="MsoNormal">
            <b>第五十四条</b>&nbsp; 幸福或其销售代理企业在旅客购票时，应告知旅客代码共享航班的实际承运人。<span></span>
        </p>
        <p class="MsoNormal">
            <b>第五十五条</b>&nbsp; 缔约承运人根据与实际承运人的代码共享协议内容办理旅客客票签转、变更、退票及乘机手续等工作。<span></span>
        </p>
        <p class="MsoNormal">
            &nbsp;
        </p>
        <h1>
            <a name="_Toc439774354"></a>第十五章<span>&nbsp; </span>乘  机<span></span>
        </h1>
        <p class="MsoNormal">
            <b>&nbsp;</b>
        </p>
        <p class="MsoNormal">
            <b>第五十六条</b>&nbsp; 一般规定<b></b>
        </p>
        <p class="MsoNormal">
            （一）办理乘机手续<span></span>
        </p>
        <p class="MsoNormal">
            1、旅客应当在幸福规定的时限内到达机场，凭客票及本人有效乘机身份证件按时办理客票查验、托运行李、领取登机牌及通过机场安全检查等乘机手续。<span></span>
        </p>
        <p class="MsoNormal">
            2、幸福开始办理航班乘机手续的时间一般不迟于客票上列明的航班离站时间前<span>90</span>分钟，除运营机场当局或幸福另有规定外，截止办理乘机手续时间均在航班规定离站时间前<span>30</span>分钟。<span></span>
        </p>
        <p class="MsoNormal">
            3、幸福及其地面服务代理人应按时开放办理乘机手续柜台，按规定接受旅客出具的客票，快速、准确地办理乘机手续。<span></span>
        </p>
        <p class="MsoNormal">
            4、持未定妥座位客票的旅客，办理乘机手续的部门可不予办理。<span></span>
        </p>
        <p class="MsoNormal">
            （二）登机<span></span>
        </p>
        <p class="MsoNormal">
            旅客办理完乘机手续通过安全检查后，必须按时到达指定的登机门。如旅客未能按时到达指定登机门，或未能做好旅行准备，幸福为不延误航班可取消旅客已定妥的座位。对旅客由此所产生的损失和费用，幸福不承担责任。<span></span>
        </p>
        <p class="MsoNormal">
            <b>第五十七条</b>&nbsp; 乘机前，旅客及其行李和免费随身携带物品必须经过安全检查。<span></span>
        </p>
        <p class="MsoNormal">
            <b>第五十八条</b>&nbsp; 旅客误机
        </p>
        <p class="Char6">
            （一）旅客如发生误机，应到幸福直属营业部或原购票地办理改期或退票手续。<span></span>
        </p>
        <p class="MsoNormal">
            （二）旅客误机后，如要求改乘后续航班，在后续航班有空余座位的情况下，幸福应积极予以安排，并参照第三十六条、第三十七条为旅客办理改期手续。<span></span>
        </p>
        <p class="MsoNormal">
            （三）旅客误机后，如要求退票，幸福或原出票代理人可按照第四十四条为旅客办理自愿退票手续。<span></span>
        </p>
        <p class="MsoNormal">
            <b>第五十九条<span>&nbsp; </span></b>旅客漏乘<span></span>
        </p>
        <p class="MsoNormal">
                （一）由于旅客原因发生漏乘，旅客要求退票，按本条件第五十八条旅客误机的规定办理；团体票旅客漏乘按第五十一条的规定处理。<span></span>
        </p>
        <p class="MsoNormal">
                （二）由于幸福原因旅客漏乘，幸福应尽早安排旅客乘坐后续航班成行。如旅客要求退票，按本条件第四十三条非自愿退票的规定办理。<span></span>
        </p>
        <p class="MsoNormal">
                <b></b><b>第六十条<span>&nbsp; </span></b>旅客错乘<span></span>
        </p>
        <p class="MsoNormal">
            （一）旅客错乘飞机，幸福应尽早安排错乘旅客乘坐后续航班飞往旅客客票上列明的目的地，票款不补不退。如因旅客原因错乘，旅客要求在错乘的到达站终止旅行，票款差额不补不退。<span></span>
        </p>
        <p class="MsoNormal">
            （二）由于幸福或机场原因错乘，幸福应尽早安排错乘旅客乘坐后续航班飞往旅客客票上列明的目的地。旅客要求退票，按本条件第四十三条非自愿退票的规定办理。<span></span>
        </p>
        <p class="MsoNormal">
            &nbsp;
        </p>
        <h1>
            <a name="_Toc439774355"></a><a name="第十三章行李运输"></a>第十六章  行李运输
        </h1>
        <p class="MsoNormal" align="center">
            <b>第一节 </b><b>一般规定<span></span></b>
        </p>
        <p class="MsoNormal">
            &nbsp;
        </p>
        <p class="MsoNormal">
            <b>第六十一条</b> 幸福承运的行李，只限于符合本条件第一条第（四十）款定义范围内的物品。幸福承运的行李，按照运输责任分为托运行李、自理行李和随身携带物品。<span></span>
        </p>
        <p class="MsoNormal">
            <b>第六十二条 </b>&nbsp;不得作为行李运输的物品<span></span>
        </p>
        <p class="MsoNormal">
            &nbsp; 下列物品不得作为行李或夹入行李内托运，也不得作为自理行李和免费随身携带物品带入客舱运输：<span></span>
        </p>
        <p class="MsoNormal">
            &nbsp; （一）危险品<span></span>
        </p>
        <p class="MsoNormal">
                1、爆炸品；<span></span>
        </p>
        <p class="MsoNormal">
                2、气体（包括易燃和非易燃无毒气体、有毒气体）；<span></span>
        </p>
        <p class="MsoNormal">
                3、易燃液体；<span></span>
        </p>
        <p class="MsoNormal">
                4、易燃固体、自燃物质和遇水释放易燃气体的物质；<span></span>
        </p>
        <p class="MsoNormal">
                5、氧化剂和有机过氧化物；<span></span>
        </p>
        <p class="MsoNormal">
                6、毒性物质和传染性物质；<span></span>
        </p>
        <p class="MsoNormal">
                7、放射性物质；<span></span>
        </p>
        <p class="MsoNormal">
                8、腐蚀性物质；<span></span>
        </p>
        <p class="MsoNormal">
                9、磁性物质；<span></span>
        </p>
        <p class="MsoNormal">
                10、具有麻醉、令人不快或其他类似性质的物质；<span></span>
        </p>
        <p class="MsoNormal">
                11、容易污损飞机的物品；<span></span>
        </p>
        <p class="MsoNormal">
                12、幸福规定不得作为行李运输的其他危险物品。<span></span>
        </p>
        <p class="MsoNormal">
                （二）枪支，含各种类型仿真玩具枪、枪型打火机及其他各种类型带有攻击性的武器，但体育运动用器械除外。<span></span>
        </p>
        <p class="MsoNormal">
                （三）军械、警械。<span></span>
        </p>
        <p class="MsoNormal">
                （四）管制刀具。<span></span>
        </p>
        <p class="MsoNormal">
                （五）活体动物，但本条件第七十五条规定的小动物及导盲犬和助听犬除外。<span></span>
        </p>
        <p class="MsoNormal">
                （六）国家规定的其它禁运物品。<span></span>
        </p>
        <p class="MsoNormal">
            第六十三条&nbsp; 不得作为托运行李运输的物品<span></span>
        </p>
        <p class="MsoNormal">
            &nbsp; 重要文件和资料、证券、货币、汇票、珠宝、贵重金属及其制品、银制品、贵重物品、古玩字画、易碎和易损坏物品、易腐物品、样品、旅行证件以及其它需要专人照管的物品不得作为托运行李或夹入行李内托运，在符合幸福关于行李重量、体积限制的情况下，作为自理行李或免费随身携带物品，由旅客带入客舱并自行保管。<span></span>
        </p>
        <p class="MsoNormal">
            第六十四条&nbsp; 限制运输的物品<span></span>
        </p>
        <p class="MsoNormal">
            &nbsp; 下列物品只有在符合幸福运输条件的情况下，并经幸福同意，方可接受运输：<span></span>
        </p>
        <p class="MsoNormal">
                （一）精密仪器、电器等类物品，应作为货物托运，如按托运行李运输，必须有符合幸福要求及该类物品应有之妥善包装，并且此类物品的重量不得计算在免费行李额内<span>,</span>按逾重行李费收取运费。<span></span>
        </p>
        <p class="MsoNormal">
                （二）体育运动用器械，包括体育运动用枪支和弹药，必须出具枪支运输许可证或者国家体育行政部门的许可证明作为托运行李运输。<span></span>
        </p>
        <p class="MsoNormal">
                （三）本条件第七十五条规定的小动物、导盲犬和助听犬。<span></span>
        </p>
        <p class="MsoNormal">
                （四）外交信袋，机要文件。<span></span>
        </p>
        <p class="MsoNormal">
                （五）旅客旅行途中使用的折叠轮椅或电动轮椅。<span></span>
        </p>
        <p class="MsoNormal">
                （六）管制刀具以外的利器、钝器，例如菜刀、水果刀、餐刀、工艺品刀、手术刀、剪刀、镰刀、演出用刀、剑、矛，古董或者作为旅游纪念品的刀、剑以及钢锉、斧子、短棍、锤子等，不能随身携带，应放入托运行李内运输。<span></span>
        </p>
        <p class="MsoNormal">
                （七）干冰、液态物品（包括酒类物品及含有酒精的饮料等）、旅客旅行途中所需的烟具、药品、化妆品等。液态物品的容积、容量应符合政府当局、幸福及有关承运人、机场的限制要求，酒类物品含有酒精的饮料不得随身携带须作为托运行李运输。<span></span>
        </p>
        <p class="MsoNormal">

        </p>
        <p class="MsoNormal" align="center">
            <b>第二节 </b><b>行李包装及体积、重量限制<span></span></b>
        </p>
        <p class="MsoNormal">
            &nbsp;
        </p>
        <p class="MsoNormal">
            第六十五条&nbsp; 托运行李<span></span>
        </p>
        <p class="MsoNormal">
                （一）托运行李必须包装完善、锁扣完好、捆扎牢固，能承受一定的压力，能够在正常的操作条件下安全装卸和运输，并应符合下列条件：<span></span>
        </p>
        <p class="MsoNormal">
                1、旅行箱、旅行袋和手提包等必须加锁；<span></span>
        </p>
        <p class="MsoNormal">
                2、两件以上的包件，不能捆为一件；<span></span>
        </p>
        <p class="MsoNormal">
                3、行李上不能附插其他物品；<span></span>
        </p>
        <p class="MsoNormal">
                4、竹篮、网兜、草绳、草袋等不能作为行李的外包装物；<span></span>
        </p>
        <p class="MsoNormal">
                5、行李包装内不能用锯末、谷壳、草屑等作衬垫物；行李上应写明旅客的姓名、详细地址、电话号码。<span></span>
        </p>
        <p class="MsoNormal">
            （二）托运行李每件重量不能超过<span>50</span>公斤，体积不能超过<span>40</span>×<span>60</span>×<span>100</span>厘米。超过上述规定的行李，应事先征得幸福的同意才能托运。<span></span>
        </p>
        <p class="MsoNormal">
                &nbsp;<b>第六十六条</b>&nbsp; 自理行李<span></span>
        </p>
        <p class="MsoNormal">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;新舟机型自理行李每位旅客只限一件，其重量不能超过<span>10</span>公斤，体积每件不超过<span>20</span>×<span>30</span>×<span>40</span>厘米。应能置于旅客的前排座位之下或封闭式行李架内。自理行李和托运行李合并计重，超出免费行李额的部分须交逾重行李费。<span></span>
        </p>
        <p class="MsoNormal">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;波音机型自理行李每位旅客只限一件，其重量不能超过<span>10</span>公斤，体积不超过<span>20</span>×<span>40</span>×<span>55</span>厘米，应能置于旅客的前排座位之下或封闭式行李架内。自理行李和托运行李合并计重，超出免费行李额的部分须交逾重行李费。<span></span>
        </p>
        <p class="MsoNormal">
            <b>第六十七条<span>&nbsp; </span></b>免费随身携带物品<span></span>
        </p>
        <p class="MsoNormal">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;新舟机型免费随身携带物品的重量，每位旅客以 5公斤为限。持头等舱客票的旅客，每人可随身携带两件物品；持公务舱或经济舱客票的旅客，每人只能随身携带一件物品。每件随身携带物品的体积不得超过20×30×40厘米。超过上述重量、件数或体积限制的随身携带物品，应作为托运行李托运。<span></span>
        </p>
        <p class="MsoNormal">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;波音机型免费随身携带物品的重量，每位旅客以5公斤为限。持头等舱客票的旅客，每人可随身携带两件物品；持公务舱或经济舱客票的旅客，每人只能随身携带一件物品。每件随身携带物品的体积不得超过20×40×55厘米。超过上述重量、件数或体积限制的随身携带物品，应作为托运行李托运。<span></span>
        </p>
        <p class="MsoNormal">
            &nbsp;
        </p>
        <p class="MsoNormal" align="center">
            <b>第三节 </b><b>免费行李额及逾重行李费<span></span></b>
        </p>
        <p class="MsoNormal">
            &nbsp;
        </p>
        <p class="MsoNormal">
            第六十八条 &nbsp;免费行李额<span></span>
        </p>
        <p class="MsoNormal">
                （一）每位旅客的免费行李额（包括托运和自理行李）：持成人或儿童客票的头等舱旅客为40公斤，经济舱旅客为20公斤。持婴儿票的旅客，无免费行李额。<span></span>
        </p>
        <p class="MsoNormal">
            <span>(</span>二）搭乘同一航班前往同一目的地的两个以上的同行旅客，如在同一时间、同一地点办理行李托运手续，其免费行李额可以按照各自的客票价等级标准合并计算。<span></span>
        </p>
        <p class="MsoNormal">
            （三）构成国际运输的国内航段，每位旅客的免费行李额按适用的国际航线免费行李额计算。<span></span>
        </p>
        <p class="MsoNormal">

        </p>
        <p class="MsoNormal">
            第六十九条&nbsp; 逾重行李费<span></span>
        </p>
        <p class="MsoNormal">
                （一）旅客的托运行李和自理行李，超过该旅客免费行李额的部分，称为逾重行李，应当支付逾重行李费。<span></span>
        </p>
        <p class="MsoNormal">
            （二）收取逾重行李费，应填开逾重行李票。<span></span>
        </p>
        <p class="MsoNormal">
            （三）逾重行李费率以每公斤按逾重行李票填开当日所适用的单程直达经济舱普通票价的<span>1.5%</span>计算，以人民币分为单位。收费总金额以元为单位，尾数四舍五入。<span></span>
        </p>
        <p class="MsoNormal">

        </p>
        <p class="MsoNormal" align="center">
            <b>第四节 </b><b>行李声明价值<span></span></b>
        </p>
        <p class="MsoNormal">
            <b>&nbsp;</b>
        </p>
        <p class="MsoNormal">
            第七十条<span></span>
        </p>
        <p class="MsoNormal">
                （一）旅客的托运行李，每公斤价值超过人民币<span>100</span>元时，可办理行李的声明价值。<span></span>
        </p>
        <p class="MsoNormal">
                （二）托运行李的声明价值不能超过行李本身的实际价值。每一旅客的行李声明价值最高限额为人民币<span>8</span>，<span>000</span>元。如幸福对声明价值有异议而旅客又拒绝接受检查时，幸福有权拒绝收运。<span></span>
        </p>
        <p class="MsoNormal">
            （三）幸福按照旅客声明的价值中超过本条第（一） 款规定限额部分的价值的<span>5</span>‰收取声明价值附加费。金额以人民币元为单位，尾数四舍五入。<span></span>
        </p>
        <p class="MsoNormal">
            （四）办理声明价值的行李不包含在免费行李额内。<span></span>
        </p>
        <p class="MsoNormal">
            &nbsp;
        </p>
        <p class="MsoNormal" align="center">
            <b>第五节 </b><b>行李的收运<span></span></b>
        </p>
        <p class="MsoNormal">
            &nbsp;
        </p>
        <p class="MsoNormal">
            第七十一条&nbsp; 拒绝运输权<span></span>
        </p>
        <p class="MsoNormal">
                （一）旅客的托运行李、自理行李和免费随身携带物品，如属于或夹带有本条件第六十二条所列的物品，幸福有权拒绝接受该行李的运输。<span></span>
        </p>
        <p class="MsoNormal">
                （二）旅客的托运行李，如属于或夹带有本条件第六十三条所列的物品，幸福有权拒绝接受该行李作为托运行李运输。<span></span>
        </p>
        <p class="MsoNormal">
                （三）旅客携带了属于本条件第六十四条所列的物品，如旅客没有或拒绝遵守幸福的限制运输条件，幸福有权拒绝接受该物品的运输。<span></span>
        </p>
        <p class="MsoNormal">
                （四）旅客的托运行李、自理行李或免费随身携带物品，如因其形态、包装、体积、重量或特性等原因不符合幸福运输条件，幸福应请旅客加以改善，如旅客不能或拒绝改善，幸福有权拒绝接受该行李的运输。<span></span>
        </p>
        <p class="MsoNormal">
            第七十二条&nbsp; 检查权<span></span>
        </p>
        <p class="MsoNormal">
            &nbsp; 幸福为了运输安全原因，可以会同旅客对其行李进行检查；必要时，可会同有关部门进行检查，检查时，旅客应当到场，对旅客未到场接受检查而发生的任何损失，幸福不承担责任，如果旅客拒绝检查，幸福有权拒绝接受该行李的运输。<span></span>
        </p>
        <p class="MsoNormal">
                <b>&nbsp;</b><b>第七十三条</b>&nbsp; 收运要求<span></span>
        </p>
        <p class="MsoNormal">
                （一）旅客必须凭有效客票托运行李。幸福应在客票及行李票上填写托运行李的件数和重量，或者在行李识别联上填写托运行李的件数。<span></span>
        </p>
        <p class="MsoNormal">
                （二）幸福只在航班离站当日办理乘机手续时收运行李。<span></span>
        </p>
        <p class="MsoNormal">
                （三）幸福对旅客托运的每件行李应拴挂行李牌，并将其中的识别联交给旅客。经幸福同意的自理行李，在与托运行李合并计重，交由旅客带入客舱自行照管。<span></span>
        </p>
        <p class="MsoNormal">
                （四）旅客托运有运输责任争议的行李时，幸福应向旅客说明情况，应经旅客同意后，拴挂免除责任行李牌，以免除幸福相应的运输责任。<span></span>
        </p>
        <p class="MsoNormal">
            第七十四条&nbsp; 行李载运<span></span>
        </p>
        <p class="MsoNormal">
            （一）旅客的托运行李，应与旅客同机运送，特殊情况下不能同机运送时，幸福应向旅客说明，并优先安排在载量允许的后续航班上运送。<span></span>
        </p>
        <p class="MsoNormal">
                （二）旅客的逾重行李在飞机载量允许的条件下，应与旅客同机运送。如载量不允许，而旅客又拒绝使用后续可利用航班运送，幸福可拒绝收运旅客的逾重行李。<span></span>
        </p>
        <p class="MsoNormal">
            第七十五条&nbsp; 小动物<span></span>
        </p>
        <p class="MsoNormal">
                （一）小动物是指家庭驯养的狗、猫、鸟或其它玩赏宠物。野生动物和具有形体怪异或易于伤人等特性的动物如蛇等，不属于小动物范围。<span></span>
        </p>
        <p class="MsoNormal">
                （二）旅客托运小动物必须在定座时提出，并提供动物检疫部门出具的动物检疫证明，经幸福同意后方可托运。<span></span>
        </p>
        <p class="MsoNormal">
                （三）旅客应在乘机的当日，在航班离站前90分钟以前，将小动物自行运至机场办理托运手续。<span></span>
        </p>
        <p class="MsoNormal">
                （四）装运小动物的容器应符合下列要求：<span></span>
        </p>
        <p class="MsoNormal">
                1、能防止小动物破坏、逃逸和防止小动物将身体某一部位伸出容器以外损伤人员、行李、货物或飞机；<span></span>
        </p>
        <p class="MsoNormal">
                2、能保证小动物站立和适当活动，保证空气流通，不致使小动物窒息；<span></span>
        </p>
        <p class="MsoNormal">
                3、能防止粪便渗溢，以免污染飞机、机上设备以及其他物品。<span></span>
        </p>
        <p class="MsoNormal">
                （五）旅客携带的小动物，必须装在货舱内运输。<span></span>
        </p>
        <p class="MsoNormal">
                （六）小动物及其容器和食物，不得计算在旅客的免费行李额内，应按逾重行李单独收费。<span></span>
        </p>
        <p class="MsoNormal">
                （七）小动物运输不能办理声明价值。<span></span>
        </p>
        <p class="MsoNormal">
            （八）旅客应对所托运的小动物承担全部责任。除幸福原因外，在运输中出现的小动物患病、受伤和死亡，幸福不承担责任。<span></span>
        </p>
        <p class="MsoNormal">
            （九）幸福<span>MA60</span>机型不承运任何形式和数量的小动物（导盲犬、助听犬除外）。<span></span>
        </p>
        <p class="MsoNormal">
            （十）导盲犬、助听犬的运输按以下规定办理：<span></span>
        </p>
        <p class="MsoNormal">
            1、旅客须出示动物身份证、驯养证、工作证、检疫证、其他书面证件、项箍上的标志或项箍、挂牌等，作为该动物是一只服务犬的证明。<span></span>
        </p>
        <p class="MsoNormal">
            2、旅客在带服务犬进入客舱时，必须在登机前为其系上牵引绳索，并不得占用座位和让其任意跑动。在征得服务犬机上活动范围内相关旅客同意的情况下，可不要求乘机人为服务犬戴口套。<span></span>
        </p>
        <p class="MsoNormal">
            3、服务犬可被允许伴随满足乘机条件的残疾人，但须保证服务犬呆在该人所坐的座位下，除非该动物阻碍了为了便于紧急撤离而须保持不被堵塞的过道或其他区域。<span></span>
        </p>
        <p class="MsoNormal">
            4、导盲犬、助听犬连同其容器和食物可以免费运输而不计算在免费行李额内。<span></span>
        </p>
        <p class="MsoNormal">
            第七十六条&nbsp; 外交信袋<span></span>
        </p>
        <p class="MsoNormal">
                （一）外交信袋应当由外交信使随身携带，自行照管。根据外交信使的要求，幸福也可以按照托运行李办理，但幸福只承担一般托运行李的责任。<span></span>
        </p>
        <p class="MsoNormal">
                （二）外交信使携带的外交信袋和行李，可以合并计重或计件，超过免费行李额部分，按照逾重行李的规定办理。<span></span>
        </p>
        <p class="MsoNormal">
                （三）外交信袋运输需占用座位时，旅客必须在定座时提出，经幸福同意，方可予以运输。<span></span>
        </p>
        <p class="MsoNormal">
                （四）占用每一座位的外交信袋的总重量不得超过<span>75</span>公斤，总体积不得超过<span>40</span>×<span>60</span>×<span>100</span>厘米。占用座位的外交信袋没有免费行李额，运费按下列两种办法计算，取其高者：<span></span>
        </p>
        <p class="MsoNormal">
                1、根据占用座位的外交信袋实际重量，按照逾重行李费率计算运费；<span></span>
        </p>
        <p class="MsoNormal">
                2、根据外交信袋占用的座位数，按照运输起讫地点之间，与该外交信使所持客票票价级别相同的票价计算运费。<span></span>
        </p>
        <p class="MsoNormal">
                （五）机要交通人员携带的机要文件，按本条的规定办理。<span></span>
        </p>
        <p class="MsoNormal">
                <b>&nbsp;</b><b>第七十七条</b>&nbsp; 违章行李<span></span>
        </p>
        <p class="MsoNormal">
            &nbsp; 旅客的托运行李、自理行李和免费随身携带物品中，凡夹带国家规定的禁运物品、限制携带物品或危险物品等，其整件行李称为违章行李。对违章行李，幸福按下列规定处理：<span> </span>
        </p>
        <p class="MsoNormal">
                （一）在始发地发现违章行李，幸福要按照本条件第七十一条第（一）、（二）款的规定拒绝收运；如已承运，有权取消运输，或将违章夹带物品取出后运输，已收逾重行李费不退。<span></span>
        </p>
        <p class="MsoNormal">
                （二）在经停地发现违章行李，应立即停运，已收逾重行李费不退。<span></span>
        </p>
        <p class="MsoNormal">
                （三）对违章行李中夹带的国家规定的禁运物品、限制携带物品或危险物品，交有关部门处理。<span></span>
        </p>
        <p class="MsoNormal">
            第七十八条&nbsp; 行李退运<span></span>
        </p>
        <p class="MsoNormal">
            （一）旅客在始发地要求退运行李，必须在行李装机前提出。如旅客退票，已收运的行李也必须同时退运。以上退运，均退还已收逾重行李费。<span></span>
        </p>
        <p class="MsoNormal">
                （二）旅客在经停地退运行李，除时间不允许外，可予以办理。但未使用航段的已收逾重行李费不退。<span></span>
        </p>
        <p class="MsoNormal">
                （三）办理声明价值的行李退运时，在始发地退还已交付的声明价值附加费，在经停地不退已交付的声明价值附加费。<span></span>
        </p>
        <p class="MsoNormal">
                （四）由于幸福的原因，需要安排旅客改乘幸福其他航班，行李运输应随旅客作相应的变更，已收逾重行李费多退少不补；已交付的声明价值附加费不退；如安排旅客签转其他承运人航班，应办理行李退运，退还已收逾重行李费和已交付的声明价值附加费。<span></span>
        </p>
        <p class="MsoNormal">
            &nbsp;
        </p>
        <p class="MsoNormal" align="center">
            <b>第六节  行李交付<span></span></b>
        </p>
        <p class="MsoNormal">
            &nbsp;
        </p>
        <p class="MsoNormal" align="left">
            <b>第七十九条</b> &nbsp;行李交付<span>&nbsp; </span>（一）旅客应在航班到达后立即在机场凭行李牌的识别联领取行李。必要时，<span></span>
        </p>
        <p class="MsoNormal" align="left">
            应交验客票。<span></span>
        </p>
        <p class="MsoNormal">
            （二）如旅客未立即领取行李，幸福从行李到达的次日起向旅客收取行李保管费。对于旅客行李中的易腐物品，幸福有权在行李到达<span>24</span>小时后予以处理。<span></span>
        </p>
        <p class="MsoNormal">
                （三）幸福凭行李牌的识别联交付行李，对于领取行李的人是否确系旅客本人，以及由此造成的损失及费用，不承担责任。<span></span>
        </p>
        <p class="MsoNormal">
                （四）旅客行李延误到达后，幸福应立即通知旅客领取，也可直接送达旅客。对延误行李不收取保管费。<span></span>
        </p>
        <p class="MsoNormal">
                （五）旅客在领取行李时，未提出书面异议，应即认为该行李已经按照运输合同完好交付。<span></span>
        </p>
        <p class="MsoNormal">
            （六）旅客遗失行李牌的识别联，应立即向幸福挂失。旅客如要求领取行李，应向幸福提供足够的证明，并在领取行李时出具收据。如在声明挂失前行李已被冒领，幸福不承担责任。<span></span>
        </p>
        <p class="MsoNormal">
                &nbsp;<b>第八十条</b>&nbsp;
        无法交付的行李<span></span>
        </p>
        <p class="MsoNormal">
            &nbsp; 行李自到达的次日起，超过<span>90</span>日仍无人认领，幸福可按照无法交付行李的有关规定处理。<span></span>
        </p>
        <p class="MsoNormal">
            第八十一条&nbsp; 行李不正常运输的处理<span></span>
        </p>
        <p class="MsoNormal">
                （一）行李运输发生延误、遗失或损坏，幸福或其授权地面服务代理人应会同旅客填写《行李运输事故记录》或《破损行李事故记录》，尽快查明情况和原因，并将调查结果答复旅客和有关单位。如发生行李赔偿，可在始发地、经停地或目的地办理。<span></span>
        </p>
        <p class="MsoNormal">
            （二）因幸福原因使旅客的托运行李未能与旅客同机到达，造成旅客旅途生活的不便，应给予旅客临时生活用品补偿费人民币<span>100</span>元<span>/</span>人。<span></span>
        </p>
        <p class="MsoNormal">
            &nbsp;
        </p>
        <h1>
            <a name="_Toc439774356"></a><a name="第十四章旅客服务"></a>第十七章<span>&nbsp; </span>旅客服务<span></span>
        </h1>
        <p class="MsoNormal" align="center">
            <b>第一节  一般服务<span></span></b>
        </p>
        <p class="MsoNormal">
            <b>&nbsp;</b>
        </p>
        <p class="MsoNormal">
            第八十二条&nbsp; 幸福应以保证飞机安全和航班正常，提供良好服务为准则，以文明礼貌、热情周到的服务态度，认真做好空中和地面的旅客运输的各项服务工作。<span></span>
        </p>
        <p class="MsoNormal">
            第八十三条&nbsp; 幸福不负责为旅客提供机场区域内、机场与市区之间或在同一城市的机场与机场之间的地面运输。对于此项地面运输服务提供者的行为或疏忽，或销售代理人为旅客取得此项地面运输服务给予的任何帮助，幸福不承担责任。<span></span>
        </p>
        <p class="MsoNormal">
            第八十四条&nbsp; 旅客在联程航班衔接地点的地面膳宿费用，应由旅客自理。<span></span>
        </p>
        <p class="MsoNormal">
            第八十五条&nbsp; 在航空运输过程中，旅客发生疾病时，幸福应积极采取措施，尽力救护。<span></span>
        </p>
        <p class="MsoNormal">
            <b>第八十六条</b>&nbsp; 空中飞行过程中，幸福按规定向旅客提供饮料或餐食。旅客要求提供超过规定的其他服务，幸福可在允许的范围内提供，并收取相应的费用。<span></span>
        </p>
        <p class="MsoNormal">
            &nbsp;
        </p>
        <p class="MsoNormal" align="center">
            <b>第二节  不正常航班的服务<span></span></b>
        </p>
        <p class="MsoNormal">
            <b>&nbsp;</b>
        </p>
        <p class="MsoNormal">
            <b>第八十七条</b>&nbsp; 由于幸福机务维护、航班调配、商务、机组等原因，造成航班在始发地延误或取消，幸福航空可根据旅客的合理需求，按照幸福航规定向旅客提供客票更改、餐食或住宿等服务，具体措施如下：<span></span>
        </p>
        <p class="MsoNormal">
            （一）客票变更、签转<span></span>
        </p>
        <p class="MsoNormal">
            1、为旅客安排有可利用座位的幸福后续航班；<span></span>
        </p>
        <p class="MsoNormal">
            2、征得旅客及有关承运人的同意，签转至与幸福航有签转协议的航班；<span></span>
        </p>
        <p class="MsoNormal">
            3、按照本规定第四十三条非自愿退票的规定办理；<span></span>
        </p>
        <p class="MsoNormal">
            （二）餐食、住宿<span></span>
        </p>
        <p class="MsoNormal" align="left">
            &nbsp;
        1、航班延误超过<span>2</span>小时以上，幸福航可根据航班不正常实际情况，在满足配餐条件的情况下，给予旅客安排餐食服务；<span></span>
        </p>
        <p class="MsoNormal" align="left">
            2、航班延误<span>4</span>小时以上，可根据实际情况，给予旅客安排酒店休息；<span></span>
        </p>
        <p class="MsoNormal" align="left">
            （三）补偿标准<span></span>
        </p>
        <p class="MsoNormal" align="left">
            因工程机务、航班计划、运输服务、空勤人员等承运人原因造成航班执行当日长时间延误（4小时含以上），幸福航空处置原则如下：<span></span>
        </p>
        <p class="MsoNormal" align="left">
            1、航班延误时间在4（含）-8小时，持成人/儿童客票旅客每人补偿人民币100元；延误8（含）小时以上，持成人/儿童客票旅客每人补偿人民币200元。 <span></span>
        </p>
        <p class="MsoNormal" align="left">
            2、 持婴儿票、免票的旅客不予补偿。<span></span>
        </p>
        <p class="MsoNormal" align="left">
            3、 最终补偿金额不得超过旅客所持客票的票面价格。 <span></span>
        </p>
        <p class="MsoNormal" align="left">
            4、持婴儿票、免票及特殊产品规定的客票不予补偿；<span></span>
        </p>
        <p class="MsoNormal" align="left">
            5、延误时间<span>4</span>小时（含）以上的航班取消，补偿标准按照本条款<span>1</span>、<span>2</span>、<span>3</span>、<span>4</span>规定执行；<span></span>
        </p>
        <p class="MsoNormal">
            第八十八条&nbsp; 由于天气、突发事件、空中交通管制、安检以及旅客等非幸福原因，造成航班在始发地延误或取消，幸福应协助旅客安排餐食和住宿，费用由旅客自理。<span></span>
        </p>
        <p class="MsoNormal">
            第八十九条&nbsp; 航班在经停地延误或取消，无论何种原因，幸福航空应按规定负责向经停旅客提供餐食或者住宿服务。国内航班发生备降，无论何种原因，幸福航空均应向备降旅客提供餐食或者住宿服务。<span></span>
        </p>
        <p class="MsoNormal">
            第九十条 &nbsp;航班延误或取消，幸福及其地面服务代理人应做好解释工作，并及时将航班延误或取消等信息通知旅客。幸福航应当优先为残疾人、老年人、孕妇、无成人陪伴儿童等需要特别照料的旅客提供服务<span></span>
        </p>
        <p class="MsoNormal">
            第九十一条&nbsp; 幸福和其他各保障部门应相互配合，认真负责，共同保障航班正常，避免不必要的航班延误。<span></span>
        </p>
                <p class="MsoNormal">
            &nbsp;
        </p>
        <h1 style="margin-left:0cm;">
            <a name="_Toc439774357"></a>第<a name="第十五章飞机上的行为"></a>十八章<span>&nbsp; </span>飞机上的行为<span></span>
        </h1>
        <p class="MsoNormal">
            &nbsp;
        </p>
        <p class="MsoNormal">
            第九十二条&nbsp; 如果旅客在飞机上的行为危及到飞机或飞机上任何人或财产的安全，或妨碍机组人员履行职责，或不遵守机组的指示，或有其他旅客有理由反对的行为，幸福可以采取其认为必要的措施，以阻止该行为的继续，包括对该旅客实施管束。<span></span>
        </p>
        <p class="MsoNormal">
                <b>&nbsp;</b><b>第九十三条 </b>&nbsp;未经幸福许可，旅客不得在飞机上使用便携式收音机、移动电话、电子游戏机或包括无线电操纵的玩具和对讲机在内的发射装置，不得在飞机上使用除便携式录放机、助听器和心脏起搏器以外的任何电子设备。<span></span>
        </p>
        <p class="MsoNormal">
            &nbsp;
        </p>
        <h1>
            <a name="_Toc439774358"></a><a name="第十六章行政手续"></a>第十九章<span>&nbsp; </span>行政手续<span></span>
        </h1>
        <p class="MsoNormal">
            &nbsp;
        </p>
        <p class="MsoNormal">
            第九十四条&nbsp; 旅客必须遵守中华人民共和国的法律、政府规定、命令、要求和旅行条件的各项规定，并应服从政府或机场管理和幸福的任何安全检查。<span></span>
        </p>
        <p class="MsoNormal">
            第九十五条 <b>&nbsp;</b>旅客应出示国家的法律、政府规定、命令、要求或旅行条件所要求的有效证件。幸福对未遵守国家法律、规定、命令、或旅行条件或其证件不符合要求的旅客，保留拒绝载运的权利。<span></span>
        </p>
        <p class="MsoNormal">
            第九十六条&nbsp; 政府有关主管部门检查旅客的托运行李或自理行李时，旅客应当到场。对旅客未到场接受检查而发生的任何损失，幸福不承担责任。<span></span>
        </p>
        <p class="MsoNormal">
            &nbsp;
        </p>
        <h1 style="margin-left:0cm;">
            <a name="_Toc439774359"></a><a name="第十七章连续承运人"></a>第二十章<span>&nbsp; </span>连续承运人<span></span>
        </h1>
        <p class="MsoNormal">
            &nbsp;
        </p>
        <p class="MsoNormal">
                <b>&nbsp;</b><b>第九十七条 </b>&nbsp;根据一本客票或一本客票和与其有关而填开的连续客票由几个连续的承运人共同承担的运输，应视为是一个单一运输。<span></span>
        </p>
        <p class="MsoNormal">
            &nbsp;
        </p>
        <h1 style="margin-left:0cm;">
            <a name="_Toc439774360"></a>
            <a name="第十八章损失责任及赔偿限额"></a>第二十一章
            <span>&nbsp; </span>损失责任与赔偿限额<span></span>
        </h1>
        <p class="MsoNormal" align="center">
            &nbsp;
        </p>
        <p class="MsoNormal">
            <b>第九十八条</b>&nbsp; 损失责任及赔偿限额<span></span>
        </p>
        <p class="MsoNormal">
            （一）一般规定<span></span>
        </p>
        <p class="MsoNormal">
            1、除另有约定外，幸福应对发生在其履行的航空运输期间的损失承担责任。幸福为其他承运人航线上填开客票或办理行李托运时，只作为其他承运人的代理人，幸福对旅客在其他承运人运输过程中发生的任何疾病、受伤、残废或死亡及行李的损毁不承担责任。<span></span>
        </p>
        <p class="MsoNormal">
            &nbsp; 2、幸福为遵守或旅客未遵守国家法律、政府规定、命令和要求而引起的任何损失，幸福不应承担责任。<span></span>
        </p>
        <p class="MsoNormal">
            3、幸福所承担的责任，应不超过经证明直接损失的数额。幸福对间接的或随之引发的损失不承担责任，幸福也不对精神损害承担责任。
        </p>
        <p class="MsoNormal">
            （二）旅客人身伤亡
        </p>
        <p class="MsoNormal">
            1、 因发生在飞机上或者在旅客上下飞机过程中的事件，造成旅客人身伤亡的，幸福承担责任；但是，旅客由于其行为、年龄、精神或身体健康状况，在运输中造成或加重其本人的疾病、受伤、残废或死亡，幸福不承担责任。
        </p>
        <p class="MsoNormal">
            2、除幸福的过失原因外，幸福对旅客在运输过程中发生的任何疾病、受伤、残废或死亡及行李的损毁不承担责任。<span></span>
        </p>
        <p class="MsoNormal">
            &nbsp; 3、幸福对每名旅客死亡、受伤的赔偿责任限额为人民币<span>400</span>，<span>000</span>元。<span></span>
        </p>
        <p class="MsoNormal">
            （三）行李损失<span></span>
        </p>
        <p class="MsoNormal">
            &nbsp; 1、由于发生在上、下飞机期间或飞机上的事件，造成旅客托运行李延误、毁灭、遗失或者损坏的，幸福应当承担责任。
        </p>
        <p class="MsoNormal">
            2、旅客行李毁灭、遗失或者损坏的，幸福按照行李降低的价值赔偿或负担修理费用。幸福对托运行李损失的赔偿金额每公斤不超过人民币100元，如行李的价值每公斤低于<span>100</span>元时，按实际价值赔偿；幸福对旅客的自理行李和免费随身携带物品损失的赔偿金额为每位旅客不超过人民币3,000元，如行李的价值低于上述限额时，按实际价值赔偿。行李的毁灭、损坏或遗失完全是由于行李本身的自然属性、质量或缺陷造成的，幸福不承担责任。<span></span>
        </p>
        <p class="MsoBodyTextIndent2">
            3、旅客如已办理行李声明价值，幸福应按声明的价值赔偿。行李的声明价值高于实际价值时，应按实际价值赔偿。<span></span>
        </p>
        <p class="MsoBodyTextIndent2">
            4、旅客的行李或行李中任何物件的重量只能以该受损行李或物件的实际重量为限；如果无法确定受损行李或物件重量，每一旅客的受损行李最多只能按该旅客享受的免费行李额来计算。
        </p>
        <p class="MsoBodyTextIndent2">
            5、旅客行李的毁灭、遗失或者损坏完全是由于行李本身的自然属性、质量或者缺陷造成的，幸福不承担责任。
        </p>
        <p class="MsoBodyTextIndent2">
            6、由于旅客自身原因，其行李造成该旅客伤害或其财产损失，幸福不承担责任。由于旅客行李对他人造成伤害或对他人物品或幸福财产造成损失，旅客应赔偿幸福的所有损失和由此支付的一切费用。
        </p>
        <p class="MsoNormal">
            7、对于旅客在托运行李内夹带的本条件第六十三条所列物品的丢失或损坏，只按一般托运行李承担赔偿责任。<span></span>
        </p>
        <p class="MsoNormal">
            8、行李赔偿时，对赔偿行李收取的逾重行李费应退还，已收取的声明价值附加费不退。<span></span>
        </p>
        <p class="MsoNormal">
            9、在联程运输中，幸福仅对发生在其承运的航线上的行李损失承担赔偿责任。<span></span>
        </p>
        <p class="MsoNormal">
            &nbsp; 10、构成国际运输的国内航段，行李赔偿按适用的国际运输行李赔偿规定办理。<span></span>
        </p>
        <p class="MsoNormal">
            11、已赔偿的丢失行李找到后，幸福应尽快通知旅客。旅客可将自己的行李领回，退还全部赔偿，临时生活用品补偿费不退。发现旅客有明显的欺诈行为，幸福有权追回全部赔偿。<span></span>
        </p>
        <p class="MsoNormal">
            （四）延误<span></span>
        </p>
        <p class="MsoNormal">
            1、旅客、行李在航空运输中因延误造成的损失，幸福承担责任，但如航班延误系非幸福因素造成的，幸福不承担责任。
        </p>
        <p class="MsoNormal">
            2、非幸福因素包括但不限于：天气原因、自然灾害、突发事件、空中交通管制、机场安检、机场未能提供正常服务、旅客自身行为、幸福为遵守法律法规、政府规定和命令采取的行为及其他无法控制或避免的因素。
        </p>
        <p class="MsoNormal">
            3、幸福证明本人或者其受雇人、代理人为了避免损失的发生，已经采取一切必要措施或者不可能采取此种措施的，不承担责任。
        </p>
        <p class="MsoNormal">
            4、幸福在安排一架飞机执行多个始发航段航班，如因第一个航段航班延误导致后续航段航班延误时，相邻<span>4</span>个航段航班延误原因视同第一个航段航班延误原因；如后续航班继续延误，则视同幸福承运人原因。
        </p>
        <p class="MsoNormal">
            （五）其他规定
        </p>
        <p class="MsoNormal">
            1、在旅客、行李运输中，经证明，损失是由索赔人的过错造成或者促成的，应当根据造成或者促成此种损失的过错的程度，相应免除或者减轻幸福的责任。旅客以外的其他人就旅客死亡或者受伤提出赔偿要求时，经证明死亡或者受伤是旅客本人的过错造成或者促成的，同样应当根据造成或者促成此种损失的过错的程度，相应免除或者减轻幸福的责任。
        </p>
        <p class="MsoNormal">
            2、幸福责任的任何免除或限制适用于幸福的代理人、雇员和代表以及将飞机提供给幸福使用的任何人及其代理人、雇员和代表。承运人和上述代理人、雇员、代表以及任何人可以支付的赔偿总额，不得超过幸福的责任限额。
        </p>
        <p class="MsoNormal">
            &nbsp;
        </p>
        <h1 style="margin-left:0cm;">
            <a name="_Toc439774361"></a>第二十二章<span>&nbsp; </span>索赔与诉讼<span></span>
        </h1>
        <p class="MsoNormal" align="center">
            <b>&nbsp;</b>
        </p>
        <p class="MsoNormal">
            &nbsp;<b> </b><b>第九十九条<span>&nbsp; </span></b>索赔<span></span>
        </p>
        <p class="MsoNormal">
            &nbsp; （一）旅客在领取行李时，发现托运行李丢失或损坏，应当场提出书面异议，取得《行李运输事故记录》，作为提出索赔的原始依据。旅客在领取行李时未提出书面异议，即应认为该行李已经按照运输合同完好交付。<span></span>
        </p>
        <p class="MsoNormal">
            （二）托运行李发生损失的，有权提出索赔的人最迟应当自收到托运行李之日起七日内提出异议或索赔要求；托运行李发生延误的，最迟应自托运行李交付旅客处置之日起<span>21</span>日内提出异议或索赔要求；旅客未在规定期限内提出异议或索赔的，依法不能向幸福提出索赔诉讼。<span></span>
        </p>
        <p class="MsoNormal">
            （三）提出异议或索赔要求时应附客票（或复印件）、行李牌的识别联、《行李运输事故记录》、证明行李内容和价格的凭证以及其他有关的证明。<span></span>
        </p>
        <p class="MsoNormal">
                <b>&nbsp;</b><b>第一百条</b>&nbsp; 诉讼<span></span>
        </p>
        <p class="MsoNormal">
            （一）航空运输的诉讼时效期间为<span>2</span>年，自飞机到达目的地、应当到达目的地或运输终止之日起计算，超过此期限未提起诉讼即丧失对损害索赔的权利。<span></span>
        </p>
        <p>
            （二）有关航空运输中发生的损失的诉讼，不论其根据如何，旅客或其他有权提出索赔的人可以依据《中华人民共和国民用航空法》、《国内航空运输承运人赔偿责任限额规定》，以及本条件规定的条件和赔偿责任限额提出。<span>&nbsp; </span>
        </p>
        <p class="MsoNormal">
            &nbsp;
        </p>
        <h1 style="">
            <a name="_Toc439774362"></a><a name="第十九章生效与修改"></a>第二十三章<span>&nbsp; </span>生效与修改
        </h1>
        <p class="MsoNormal">
            &nbsp;
        </p>
        <p class="MsoNormal">
                <b>&nbsp;</b><b>第一百零一条</b>&nbsp; 本条件自<span>2019</span>年<span>04</span>月<span>20</span>日起生效并施行。
        </p>
        <p class="MsoNormal">
                <b>&nbsp;</b><b>第一百零二条</b>&nbsp; 幸福有权依照中国民用航空局规定的程序，不经通知修改其运输条件、运输规定、票价和费用。但此类修改不适用于修改前已经开始的运输。幸福的工作人员、销售代理人或雇员都无权更改或违反幸福适用的运输条件、运输规定、票价和费用。<span></span>
        </p>
        <p class="MsoNormal">
            <b>&nbsp;</b>
        </p>
        <p class="MsoNormal">
            <b>&nbsp;</b>
        </p>
        <p class="MsoNormal">
            <b>&nbsp;</b>
        </p>
        <p></p>
        <p></p></div>
    </div>
    </div>
</template>

<script>
export default {}
</script>

<style lang="less" scoped>
h1 {
    font-size: 20px;
}
</style>
